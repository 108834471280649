.main-div{
  display: flex;
  flex-direction: column;
align-items:center;
  align-self: center;
  justify-self: center;
}
.search-bar {
  display: flex;
  flex-direction: column;
align-items:center;
  border-radius: 1.8rem;
  align-self: center;
  justify-self: center;
}
.search-bar .filterline1 {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  flex:1;
  margin-top: 1vw;
  padding-left: 1vw;
}


.search-bar .date-picker .from-date{
  height: 1rem;
}
.search-bar .filter-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.filter-bar-form {
  display: flex;
  align-items: center;
  justify-content: center;
}
.column-form {
  max-width:'20vw'
}
.search-bar .divider {
  margin: 0rem 0.5vw;
  height:2rem
}
.searchButton{
  height: 3vw;
  width: 7vw;
  border-radius: 3vw
}
.searchButtonIcon{
  height: 40px;
  width: 40px;
}
.weeklyButton{
   color: #005400;
   height: 3rem;
   width: 10rem
}

.margin{
  margin: 10px;
}


.gridsm{
  align-items: center;
  justify-content: center;
}
.table-head{
  font-size: 1vw;
}

/* @media only screen and (max-width: 1500px) {
  .search-bar {
    margin: 5px;
    padding: 10px;
  }
  .search-bar .date-picker .from-date{
    margin-bottom: 0;
  }
  .search-bar .filterline1 {
   justify-content: center;
  }
  .search-bar .filter-bar {
    width:15vw ;
  }
  .search-bar .filterline1.filter-bar .filter-bar-form {
    width:30vw;
  }

} */
@media only screen and (max-width: 700px) {
  .search-bar {
    border-radius: 1.8rem;
    width: 80vw;
  }
  .search-bar .filterline1 {
    flex-direction: column;
    flex-wrap: wrap;
  }
  .search-bar .filterline1 .divider {
    margin: 0.5rem 0vw;
    height:0rem;
    visibility: hidden;
  }
.filter-bar {
    width:30vw;
    margin: auto;
    align-self: center;
    justify-self: center;
  }
 .filter-bar .filter-bar-form {
    width:30vw;
  }
  .searchButton{
    height: 8vw;
    width: 14vw;
    border-radius: 8vw
  }
  .searchButtonIcon{
    height: 7rem;
    width: 7vw;
  }
  .column-form {
    max-width:'40vw'
  }
  .table-head{
    font-size: 4vw;
  }
  
}